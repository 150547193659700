<head>
  <meta charset="utf-8">
  <meta content="width=device-width, initial-scale=1.0" name="viewport">

  <meta content="" name="description">
  <meta content="" name="keywords">

  <!-- Favicons -->
  <link href="/assets/img/favicon.png" rel="icon">
  <link href="/assets/img/apple-touch-icon.png" rel="apple-touch-icon">

  <!-- Google Fonts -->
  <link
    href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Roboto:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
    rel="stylesheet">

  <!-- Vendor CSS Files -->
  <link href="/assets/vendor/animate.css/animate.min.css" rel="stylesheet">
  <link href="/assets/vendor/aos/aos.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap/css/bootstrap.min.css" rel="stylesheet">
  <link href="/assets/vendor/bootstrap-icons/bootstrap-icons.css" rel="stylesheet">
  <link href="/assets/vendor/boxicons/css/boxicons.min.css" rel="stylesheet">
  <link href="/assets/vendor/glightbox/css/glightbox.min.css" rel="stylesheet">
  <link href="/assets/vendor/remixicon/remixicon.css" rel="stylesheet">
  <link href="/assets/vendor/swiper/swiper-bundle.min.css" rel="stylesheet">

  <!-- Template Main CSS File -->
  <link href="/assets/css/style.css" rel="stylesheet">

  <!-- =======================================================
  * Template Name: Company - v4.7.0
  * Template URL: https://bootstrapmade.com/company-free-html-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== -->
</head>

<header id="header" class="fixed-top animate__animated animate__fadeIn" style="background-color: #C8102E;">

  <div class="container d-flex align-items-center" style="background-color: #C8102E;">

    <h1 class="logo me-auto"><a href="admin/indice"><span><img src="assets/img/letrero.png" alt=""
            class="img-fluid"> Administrador Espacios Publicitarios</span></a></h1>
    <!--Uncomment below if you prefer to use an image logo
    <a href="index.html" class="logo me-auto me-lg-0"><img src="assets/img/letrero.png" alt="" class="img-fluid"></a>-->

    <nav id="navbar" class="navbar order-last order-lg-0">
      <ul>
        <li><a href="admin/indice" class="a">Inicio</a></li>
        <li><a href="admin/usuarios" class="a">Usuarios</a></li>
        <li><a href="admin/registros" class="a">Registros</a></li>
        <li><a href="admin/ubicaciones" class="a">Ubicaciones</a></li>
      </ul>
      <button class="bi bi-list mobile-nav-toggle" style="background: none; border: none;" data-bs-toggle="collapse"
        data-bs-parent="#accordion" href="#collapse1">
      </button>
    </nav>

    <nav id="navbar" class="navbar order-last order-lg-0" style="justify-content: right;">
      <ul>
        <li><a (click)="cerrar()" href="admin/login" class="a">Cerrar Sesión</a></li>
      </ul>
    </nav>

  </div>
</header><!-- End Header -->
