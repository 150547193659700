import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-letreros',
  templateUrl: './letreros.component.html',
  styleUrls: ['./letreros.component.scss']
})
export class LetrerosComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  spinner: any

  pages: number = 1;
  intactos: any;
  danados: any;

  noEncontrado: any;
  noEncontradoIn: any;

  //CargaDeContenido
  cargando: any;
  cargandoIn: any;

  //Busqueda
  buscando: any;
  buscandoIn: any;

  es: any;
  idEs: any;
  domicilioEs: any;
  duenoEs: any;
  imgEs: any;


  danadasLength: any;
  intactasLength: any;

  constructor(private router: Router, private general: GeneralService) { }

  ngOnInit() {
    this.buscando = false;
    this.noEncontrado = false;
    this.buscandoIn = false;
    this.noEncontradoIn = false;
    this.cargando = true;
    this.cargandoIn = true;

    this.general.obternerLista(1, 1).subscribe(res => {
      this.intactos = res.body;
      this.intactasLength = this.intactos.length;
      console.log(this.intactos);
      this.cargando = false;
    });

    this.general.obternerLista(1, 0).subscribe(res => {
      this.danados = res.body;
      this.danadasLength = this.danados.length;
      console.log(this.danados);
      this.cargandoIn = false;
    });
  }

  cambio() {
    this.pages = 1;
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;
      this.general.buscarCaptura(columName, value, 1, 1).subscribe((res: any) => {
        this.intactos = res.body
        this.pages = 1;
        if (this.intactos.length === 0) {
          this.noEncontrado = true;
          this.buscando = false;
        } else {
          this.noEncontrado = false;
          this.buscando = false;
        }
      });
    } else {
      this.general.obternerLista(1, 1).subscribe(res => {
        this.intactos = res.body
        this.pages = 1;
        this.noEncontrado = false;
        this.buscando = false;
        this.intactasLength = this.intactos.length;
        console.log(this.intactos);
      });
    }
  }

  buscarIn(): void {
    let columName: string = this.cbCampoIn.nativeElement.value;
    let value: any = this.ctCadenaIn.nativeElement.value;

    if (value.length) {
      this.buscandoIn = true;
      this.general.buscarCaptura(columName, value, 1, 0).subscribe((res: any) => {
        this.danados = res.body
        this.pages = 1;
        if (this.danados.length === 0) {
          this.noEncontradoIn = true;
          this.buscandoIn = false;
        } else {
          this.noEncontradoIn = false;
          this.buscandoIn = false;
        }
      });
    } else {
      this.general.obternerLista(1, 0).subscribe(res => {
        this.danados = res.body
        this.pages = 1;
        this.noEncontradoIn = false;
        this.buscandoIn = false;
        this.danadasLength = this.danados.length;
        console.log(this.danados);
      })
    }
  }

  ver(id: any) {
    this.spinner = true;
    this.general.obtenerBuzonRegistroID(id).subscribe(res => {
      this.es = res.body;
      this.domicilioEs = this.es[0].DOMICILIO;
      this.duenoEs = this.es[0].DUENO;
      this.imgEs = this.es[0].IMAGEN;
      let dialog = document.getElementById("modalVer") as HTMLDialogElement;
      dialog.showModal();
      this.spinner = false;
    })
  }

  cerrarModalVer() {
    let dialog = document.getElementById("modalVer") as HTMLDialogElement;
    dialog.close();
  }

  regresar(){
    this.router.navigate(['admin/registros'])
  }
}

