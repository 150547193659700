<div class="principal">
  <div class="grid-container animate__animated animate__fadeInUp" >
    <div class="grid-item" (click)="usuarios()">
      <img class="img-grid" src="assets/img/us.png" />
      <label class="etiqueta">Usuarios</label>
    </div>
    <div class="grid-item" (click)="registros()">
      <img class="img-grid" src="assets/img/cap1.png" />
      <label class="etiqueta">Registros</label>
    </div>
    <div class="grid-item" (click)="ubicaciones()">
      <img class="img-grid" src="assets/img/map.png" />
      <label class="etiqueta">Ubicaciones</label>
    </div>
  </div>
</div>
