import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { SweetAlertService } from 'src/services/sweet-alert.service';
import { usuariosService } from 'src/services/usuarios.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent {
  @ViewChild('cbCampo') cbCampo: ElementRef;
  @ViewChild('ctCadena') ctCadena: ElementRef;

  @ViewChild('cbCampoIn') cbCampoIn: ElementRef;
  @ViewChild('ctCadenaIn') ctCadenaIn: ElementRef;

  spinner: any

  pages: number = 1;
  capturistas: any;
  inspectores: any;

  noEncontrado: any;
  noEncontradoIn: any;

  //CargaDeContenido
  cargando: any;
  cargando1: any;
  cargandoIn: any;

  //Busqueda
  buscando: any;
  buscandoIn: any;

  capturistaEditar: any;
  usuarioActual: any;
  contrasenaActual: any;
  usuarioNuevo: any;
  contrasenaNueva: any;
  idEditar: any;


  inspectorEditar: any;
  usuarioActualIn: any;
  contrasenaActualIn: any;
  usuarioNuevoIn: any;
  contrasenaNuevaIn: any;
  idEditarIn: any;

  editarCapturistaForm = new FormGroup({
    ID: new FormControl(),
    USUARIO: new FormControl(''),
    CONTRASENA: new FormControl('')
  })

  capturistaForm = new FormGroup({
    USUARIO: new FormControl(''),
    CONTRASENA: new FormControl('')
  })

  editarInspectorForm = new FormGroup({
    ID: new FormControl(),
    USUARIO: new FormControl(''),
    CONTRASENA: new FormControl('')
  })

  InspectorForm = new FormGroup({
    USUARIO: new FormControl(''),
    CONTRASENA: new FormControl('')
  })

  constructor(private router: Router, private usuarios: usuariosService, private alerts: SweetAlertService) { }

  ngOnInit() {
    this.buscando = false;
    this.noEncontrado = false;
    this.buscandoIn = false;
    this.noEncontradoIn = false;
    this.cargando = true;
    this.cargandoIn = true;

    this.usuarioNuevo = '';
    this.contrasenaNueva = '';
    this.usuarioNuevoIn = '';
    this.contrasenaNuevaIn = '';

    this.usuarios.listaCapturistas().subscribe(res => {
      this.capturistas = res.body
      console.log(this.capturistas);
      this.cargando = false;
    });

    this.usuarios.listaInspectores().subscribe(res => {
      this.inspectores = res.body
      console.log(this.inspectores);
      this.cargandoIn = false;
    });
  }

  cambio() {
    this.pages = 1;
  }

  buscar(): void {
    let columName: string = this.cbCampo.nativeElement.value;
    let value: any = this.ctCadena.nativeElement.value;

    if (value.length) {
      this.buscando = true;
      this.usuarios.buscarCapturista(columName, value).subscribe((res: any) => {
        this.capturistas = res.body
        this.pages = 1;
        if (this.capturistas.length === 0) {
          this.noEncontrado = true;
          this.buscando = false;
        } else {
          this.noEncontrado = false;
          this.buscando = false;
        }
      });
    } else {
      this.usuarios.listaCapturistas().subscribe(res => {
        this.capturistas = res.body
        this.pages = 1;
        this.noEncontrado = false;
        this.buscando = false;
        console.log(this.capturistas);
      })
    }
  }

  buscarIn(): void {
    let columName: string = this.cbCampoIn.nativeElement.value;
    let value: any = this.ctCadenaIn.nativeElement.value;

    if (value.length) {
      this.buscandoIn = true;
      this.usuarios.buscarInspectores(columName, value).subscribe((res: any) => {
        this.inspectores = res.body
        this.pages = 1;
        if (this.inspectores.length === 0) {
          this.noEncontradoIn = true;
          this.buscandoIn = false;
        } else {
          this.noEncontradoIn = false;
          this.buscandoIn = false;
        }
      });
    } else {
      this.usuarios.listaInspectores().subscribe(res => {
        this.inspectores = res.body
        this.pages = 1;
        this.noEncontradoIn = false;
        this.buscandoIn = false;
        console.log(this.inspectores);
      })
    }
  }

  modalCapturista() {
    let dialog = document.getElementById("modalCapturista") as HTMLDialogElement;
    dialog.showModal();
  }

  cerrarModalCapturista() {
    let dialog = document.getElementById("modalCapturista") as HTMLDialogElement;
    dialog.close();
  }


  modalInspector() {
    let dialog = document.getElementById("modalInspector") as HTMLDialogElement;
    dialog.showModal();
  }

  cerrarModalInspector() {
    let dialog = document.getElementById("modalInspector") as HTMLDialogElement;
    dialog.close();
  }


  verEditar(id: any) {
    this.usuarios.verCapturistaID(id).subscribe(res => {
      this.capturistaEditar = res.body;
      this.usuarioActual = this.capturistaEditar[0].USUARIO;
      this.contrasenaActual = this.capturistaEditar[0].CONTRASENA;
      this.idEditar = this.capturistaEditar[0].ID;
      let dialog = document.getElementById("modalEditar") as HTMLDialogElement;
      dialog.showModal();
    })
  }

  cerrarModalEditar() {
    let dialog = document.getElementById("modalEditar") as HTMLDialogElement;
    dialog.close();
  }

  verEditarIn(id: any) {
    this.usuarios.verInspectorID(id).subscribe(res => {
      this.inspectorEditar = res.body;
      this.usuarioActualIn = this.inspectorEditar[0].USUARIO;
      this.contrasenaActualIn = this.inspectorEditar[0].CONTRASENA;
      this.idEditarIn = this.inspectorEditar[0].ID;
      let dialog = document.getElementById("modalEditarIn") as HTMLDialogElement;
      dialog.showModal();
    })
  }

  cerrarModalEditarIn() {
    let dialog = document.getElementById("modalEditarIn") as HTMLDialogElement;
    dialog.close();
  }

  agregarCapturista() {
    this.cerrarModalCapturista();
    this.alerts.alertaConfirmacionAgregar('Agregar Capturista', '¿Desea agregar el nuevo capturista?').then((res: any) => {
      if (res.isConfirmed) {
        if (this.capturistaForm.value.USUARIO !== '' &&
          this.capturistaForm.value.CONTRASENA !== '') {
          console.log(this.capturistaForm.value);

          this.usuarios.nuevoCapturista(this.capturistaForm.value).subscribe(resp => {
            this.alerts.realizado('Completado', 'Se ha agregado el nuevo capturista con exito').then((res: any) => {
              this.usuarios.listaCapturistas().subscribe(res => {
                this.capturistas = res.body
                console.log(this.capturistas);
                this.capturistaForm.reset();
                this.cargando = false;
              });
            })
          }, error => {
            this.alerts.alertaError('Ocurrio un error', 'Revise su conexión o intentelo de nuevo').then(res => {
              this.modalCapturista();
            });
          })
        } else {
          this.alerts.alertaError('Error', 'Todos los campos son obligatorios').then(res => {
            this.modalCapturista();
          });
        }
      }
    })
  }

  agregarInspector() {
    this.cerrarModalInspector();
    this.alerts.alertaConfirmacionAgregar('Agregar Inspector', '¿Desea agregar el nuevo inspector?').then((res: any) => {
      if (res.isConfirmed) {
        if (this.InspectorForm.value.USUARIO !== '' &&
          this.InspectorForm.value.CONTRASENA !== '') {
          console.log(this.InspectorForm.value);

          this.usuarios.nuevoInspector(this.InspectorForm.value).subscribe(resp => {
            this.alerts.realizado('Completado', 'Se ha agregado el nuevo inspector con exito').then((res: any) => {
              this.usuarios.listaInspectores().subscribe(res => {
                this.inspectores = res.body
                console.log(this.inspectores);
                this.InspectorForm.reset();
                this.cargandoIn = false;
              });
            })
          }, error => {
            this.alerts.alertaError('Ocurrio un error', 'Revise su conexión o intentelo de nuevo').then(res => {
              this.modalInspector();
            });
          })
        } else {
          this.alerts.alertaError('Error', 'Todos los campos son obligatorios').then(res => {
            this.modalInspector();
          });
        }
      }
    })
  }

  editarCapturista() {
    this.cerrarModalEditar();
    this.alerts.alertaConfirmacion('Editar Capturista', '¿Desea guardar los cambios?').then((res: any) => {
      if (res.isConfirmed) {

        this.editarCapturistaForm.setValue({
          'ID': this.idEditar,
          'USUARIO': this.usuarioNuevo,
          'CONTRASENA': this.contrasenaNueva
        })

        if (this.editarCapturistaForm.value.USUARIO !== '' &&
          this.editarCapturistaForm.value.CONTRASENA !== '') {
          console.log(this.editarCapturistaForm.value);

          this.usuarios.actualizarCapturista(this.editarCapturistaForm.value).subscribe(resp => {
            this.alerts.realizado('Completado', 'Se guardaron los cambios con exito').then((res: any) => {
              this.usuarios.listaCapturistas().subscribe(res => {
                this.capturistas = res.body
                console.log(this.capturistas);
                this.usuarioNuevo = '';
                this.contrasenaNueva = '';
                this.cargando = false;
              });
            })
          }, error => {
            this.alerts.alertaError('Ocurrio un error', 'Revise su conexión o intentelo de nuevo').then(res => {
              this.verEditar(this.idEditar);
            });
          })
        } else {
          this.alerts.alertaError('Error', 'Todos los campos son obligatorios').then(res => {
            this.verEditar(this.idEditar);
          });
        }
      }
    })
  }

  editarInspector() {
    this.cerrarModalEditarIn();
    this.alerts.alertaConfirmacion('Editar Inspector', '¿Desea guardar los cambios?').then((res: any) => {
      if (res.isConfirmed) {

        this.editarInspectorForm.setValue({
          'ID': this.idEditarIn,
          'USUARIO': this.usuarioNuevoIn,
          'CONTRASENA': this.contrasenaNuevaIn
        })

        if (this.editarInspectorForm.value.USUARIO !== '' &&
          this.editarInspectorForm.value.CONTRASENA !== '') {
          console.log(this.editarInspectorForm.value);

          this.usuarios.actualizarInspector(this.editarInspectorForm.value).subscribe(resp => {
            this.alerts.realizado('Completado', 'Se guardaron los cambios con exito').then((res: any) => {
              this.usuarios.listaInspectores().subscribe(res => {
                this.inspectores = res.body
                console.log(this.inspectores);
                this.usuarioNuevoIn = '';
                this.contrasenaNuevaIn = '';
                this.cargandoIn = false;
              });
            })
          }, error => {
            this.alerts.alertaError('Ocurrio un error', 'Revise su conexión o intentelo de nuevo').then(res => {
              this.verEditarIn(this.idEditarIn);
            });
          })
        } else {
          this.alerts.alertaError('Error', 'Todos los campos son obligatorios').then(res => {
            this.verEditarIn(this.idEditarIn);
          });
        }
      }
    })
  }
}
