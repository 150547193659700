import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RespuestaAPI } from "src/interface/api-responses.model";
import { environment } from 'src/environments/environment';
import { Observable, Subject } from "rxjs";


const API = "https://api.movil.saion.mx";
const API2 = "https://api.guarderia.saion.mx";

@Injectable({
  providedIn: 'root'
})

export class GeneralService {

  constructor(private http: HttpClient) { }

  nuevoRegistro(form: any) {
    let direccion = API + "/ventanilla/capturas/nueva";
    return this.http.post<RespuestaAPI>(direccion, form);
  }

  actualizarRegistro(form: any) {
    let direccion = API + "/ventanilla/capturas/actualizar/registro"
    return this.http.put<RespuestaAPI>(direccion, form);
  }

  obtenerBuzonRegistroID(id: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/capturas/ver/${id}`);
  }

  obternerLista(tipo: any, estatus: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/capturas/lista/${tipo}/${estatus}`);
  }

  buscarCaptura(columna: string, valor: any, tipo: any, estatus: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/capturas/buscar/${columna}/${valor}/${tipo}/${estatus}`);
  }

  nuevaObservacion(form: any) {
    let direccion = API + "/ventanilla/observaciones/nueva";
    return this.http.post<RespuestaAPI>(direccion, form);
  }

  obtenerObservacionID(id: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/observaciones/ver/${id}`);
  }

  obternerListaObservaciones(id: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/observaciones/lista/${id}`);
  }
}
