import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare var svg: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(private router: Router) { }


  ngOnInit(): void {

  }

  usuarios(){
    this.router.navigate(['admin/usuarios']);
  }

  registros(){
    this.router.navigate(['admin/registros']);
  }

  ubicaciones(){
    this.router.navigate(['admin/ubicaciones']);
  }
}
