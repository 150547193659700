<div class="principal">
  <div class="grid-container animate__animated animate__fadeInUp" >
    <div class="grid-item" (click)="espacios()">
      <img class="img-grid" src="/assets/img/letrero.png" />
      <label class="etiqueta">Espacios Publicitarios</label>
    </div>
    <div class="grid-item" (click)="calcominias()">
      <img class="img-grid" src="assets/img/auto.png" />
      <label class="etiqueta">Calcomonías</label>
    </div>
  </div>
</div>
