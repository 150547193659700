/* eslint-disable @typescript-eslint/type-annotation-spacing */
/* eslint-disable no-trailing-spaces */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RespuestaAPI } from "src/interface/api-responses.model";
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})


export class usuariosService {
  url: string = API + "/";

  constructor(private http: HttpClient, private router: Router) { }

  listaCapturistas() {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/capturistas/lista`);
  }

  verCapturistaID(id: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/capturistas/ver/${id}`);
  }

  actualizarCapturista(form: any) {
    let direccion = this.url + "ventanilla/capturistas/actualizar"
    return this.http.put<RespuestaAPI>(direccion, form);
  }

  buscarCapturista(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/buscar/capturistas/${columna}/${valor}`);
  }

  nuevoCapturista(form: any) {
    let direccion = API + "/ventanilla/capturistas/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form);
  }

  listaInspectores() {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/inspectores/lista`);
  }

  verInspectorID(id: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/inspectores/ver/${id}`);
  }

  actualizarInspector(form: any) {
    let direccion = this.url + "ventanilla/inspectores/actualizar"
    return this.http.put<RespuestaAPI>(direccion, form);
  }

  buscarInspectores(columna: string, valor: any) {
    return this.http.get<RespuestaAPI>(API + `/ventanilla/buscar/inspectores/${columna}/${valor}`);
  }

  nuevoInspector(form: any) {
    let direccion = API + "/ventanilla/inspectores/nuevo";
    return this.http.post<RespuestaAPI>(direccion, form);
  }
}
