import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-registros',
  templateUrl: './registros.component.html',
  styleUrls: ['./registros.component.scss']
})
export class RegistrosComponent {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  espacios(){
    this.router.navigate(['admin/registros/espacios']);
  }

  calcominias(){
    this.router.navigate(['admin/registros/calcomonias']);
  }
}
