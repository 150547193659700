<head>
  <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
    integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY=" crossorigin="" />

  <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
    integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo=" crossorigin=""></script>
</head>

<body>
  <section style="background-color: rgb(255, 255, 255);margin-top: 100px;" class="services section-bg">
    <div class="row animate__animated animate__fadeInDown">
      <div class="col-1">
      </div>
      <div class="col-10">
        <div class="map-container">
          <div class="map-frame">
            <div id="map"></div>
          </div>
        </div>
      </div>
      <div class="col-1">
      </div>
    </div>
    <div class="row animate__animated animate__fadeInDown" style="margin-top: 3vh;">
      <div class="col-3"></div>
      <div class="col-6" style="justify-content: center; display: flex; font-size: 20px;">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" [checked]="estado" (change)="cambioLp($event)">
          <label class="form-check-label" for="flexSwitchCheckChecked" *ngIf="this.estado === true">Intactos ({{this.letreros.length}})</label>
          <label class="form-check-label" for="flexSwitchCheckChecked" *ngIf="this.estado === false">Dañados ({{this.letreros.length}})</label>
        </div>
      </div>
      <div class="col-3"></div>
    </div>
  </section>
</body>

