<section style="background-color: #F5F5F5; height: 6vh; width: 100%;"></section>

<div class="wrapper animate__fadeIn animate__animated">

  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active pill" id="pills-activos-tab" data-bs-toggle="pill" data-bs-target="#activos"
        type="button" role="tab" aria-selected="true" (click)="cambio()">Capturistas</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link pill" id="pills-inactivos-tab" data-bs-toggle="pill" data-bs-target="#inactivos"
        type="button" role="tab" aria-selected="false" (click)="cambio()">Inspectores</button>
    </li>
  </ul>

  <section style="background-color: white; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel" style="padding-left: 2vh; padding-right: 2vh;">
        <div class="row">
          <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
            <button class="boton" (click)="modalCapturista()">Agregar Capturista</button>
          </div>
        </div>
        <div style="justify-content: right; display: flex; width: 100%;">
          <select hidden name="filtro" id="filtro" #cbCampo
            style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
            <option selected value="USUARIO">Usuario</option>
          </select>
          <input type="text"
            style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px; border-top-left-radius: 7px; border-bottom-left-radius: 7px;"
            placeholder="Ingrese un usuario para buscar" (keyup)="buscar()" #ctCadena>
          <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
            <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscando === false"></i>
            <div class="d-flex justify-content-center" *ngIf="this.buscando === true">
              <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
            </div>
          </button>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh;">
          <thead style="color: #C8102E;">
            <th scope="col">Número</th>
            <th scope="col">Usuario</th>
            <th scope="col"></th>
          </thead>
          <tbody *ngIf="this.cargando === false">
            <tr *ngFor="let dato of capturistas | paginate: { itemsPerPage: 10, currentPage: pages, id:'activos' }">
              <th>{{dato.ID}}</th>
              <td>{{dato.USUARIO}}</td>
              <td><button class="accion" (click)="verEditar(dato.ID)"><i class="fa fa-pencil-square-o"></i> Editar</button></td>
            </tr>
            <tr *ngIf="this.noEncontrado === true">
              <td style="justify-content: center; text-align: center;" colspan="3"><strong>No se encontro ningun
                  usuario con ese dato</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="3">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #C8102E;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>


      <div class="tab-pane" id="inactivos" role="tabpanel" style="padding-left: 2vh; padding-right: 2vh;">
        <div class="row">
          <div style="justify-content: left; display: flex; width: 100%;" class="col-sm-2">
            <button class="boton" (click)="modalInspector()">Agregar Inspector</button>
          </div>
        </div>
        <div style="justify-content: right; display: flex; width: 100%;">
          <select hidden name="filtro" id="filtro" #cbCampoIn
            style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
            <option selected value="USUARIO">Usuario</option>
          </select>
          <input type="text"
            style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px; border-top-left-radius: 7px; border-bottom-left-radius: 7px;"
            placeholder="Ingrese un usuario para buscar" (keyup)="buscarIn()" #ctCadenaIn>
          <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
            <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscandoIn === false"></i>
            <div class="d-flex justify-content-center" *ngIf="this.buscandoIn === true">
              <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
            </div>
          </button>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #C8102E;">
            <th scope="col">Número</th>
            <th scope="col">Usuario</th>
            <th scope="col"></th>
          </thead>
          <tbody *ngIf="this.cargandoIn === false">
            <tr *ngFor="let dato of inspectores | paginate: { itemsPerPage: 10, currentPage: pages, id:'inactivos' }">
              <th>{{dato.ID}}</th>
              <td>{{dato.USUARIO}}</td>
              <td><button class="accion" (click)="verEditarIn(dato.ID)"><i class="fa fa-pencil-square-o"></i> Editar</button></td>
            </tr>
            <tr>
              <td *ngIf="this.noEncontradoIn === true" style="justify-content: center; text-align: center;" colspan="3">
                <strong>No se encontro ningun
                  usuario con ese dato</strong>
              </td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargandoIn === true">
            <td colspan="3">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #C8102E;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="inactivos" class="my-pagination" (pageChange)="pages = $event"
            previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Inicio Modal Nuevo Usuario -->
<dialog id="modalCapturista" style="border: none; border-radius: 1vh; width: 60%;"
  class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalCapturista()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Nuevo Capturista</h2>
  </div>
  <form [formGroup]="capturistaForm">
    <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

      <div class="col-1"></div>
      <div class="col-10">
        <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Usuario:</strong>
          <input type="text" class="form-control" aria-describedby="basic-addon1" formControlName="USUARIO">
        </label>
      </div>
      <div class="col-1"></div>

      <div class="col-1"></div>
      <div class="col-10">
        <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Contraseña:</strong>
          <input type="text" class="form-control" aria-describedby="basic-addon1" formControlName="CONTRASENA">
        </label>
      </div>
      <div class="col-1"></div>

      <div class="col-1"></div>
      <div class="col-10" style="justify-content: center; display: flex;">
        <button class="boton" (click)="agregarCapturista()">Agregar Capturista</button>
      </div>
      <div class="col-1"></div>

    </div>
  </form>
</dialog>
<!-- Final Modal Nuevo Usuario -->

<!-- Inicio Modal Editar Usuario -->
<dialog id="modalEditar" style="border: none; border-radius: 1vh; width: 60%;"
  class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalEditar()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Editar Capturista</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Usuario Actual:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" disabled [(ngModel)]="usuarioActual">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Contraseña Actual:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" disabled [(ngModel)]="contrasenaActual">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nuevo Usuario:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="usuarioNuevo">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nueva Contraseña:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="contrasenaNueva">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10" style="justify-content: center; display: flex;">
      <button class="boton" (click)="editarCapturista()">Guardar</button>
    </div>
    <div class="col-1"></div>

  </div>
</dialog>
<!-- Final Modal Editar Usuario -->

<!-- Inicio Modal Nuevo Usuario -->
<dialog id="modalInspector" style="border: none; border-radius: 1vh; width: 60%;"
  class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalInspector()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Nuevo Inspector</h2>
  </div>
  <form [formGroup]="InspectorForm">
    <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

      <div class="col-1"></div>
      <div class="col-10">
        <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Usuario:</strong>
          <input type="text" class="form-control" aria-describedby="basic-addon1" formControlName="USUARIO">
        </label>
      </div>
      <div class="col-1"></div>

      <div class="col-1"></div>
      <div class="col-10">
        <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Contraseña:</strong>
          <input type="text" class="form-control" aria-describedby="basic-addon1" formControlName="CONTRASENA">
        </label>
      </div>
      <div class="col-1"></div>

      <div class="col-1"></div>
      <div class="col-10" style="justify-content: center; display: flex;">
        <button class="boton" (click)="agregarInspector()">Agregar Inspector</button>
      </div>
      <div class="col-1"></div>

    </div>
  </form>
</dialog>
<!-- Final Modal Nuevo Usuario -->

<!-- Inicio Modal Editar Usuario -->
<dialog id="modalEditarIn" style="border: none; border-radius: 1vh; width: 60%;"
  class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalEditarIn()"><i class="fa fa-times"
        style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Editar Inspector</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row">

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Usuario Actual:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" disabled [(ngModel)]="usuarioActualIn">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Contraseña Actual:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" disabled [(ngModel)]="contrasenaActualIn">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nuevo Usuario:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="usuarioNuevoIn">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Nueva Contraseña:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" [(ngModel)]="contrasenaNuevaIn">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10" style="justify-content: center; display: flex;">
      <button class="boton" (click)="editarInspector()">Guardar</button>
    </div>
    <div class="col-1"></div>

  </div>
</dialog>
<!-- Final Modal Editar Usuario -->

