<section style="background-color: #F5F5F5; height: 6vh; width: 100%;"></section>

<div class="wrapper animate__fadeIn animate__animated">

  <h1>Espacios Publicitarios</h1>

  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button class="nav-link active pill" id="pills-activos-tab" data-bs-toggle="pill" data-bs-target="#activos"
        type="button" role="tab" aria-selected="true" (click)="cambio()">Intactos</button>
    </li>
    <li class="nav-item" role="presentation">
      <button class="nav-link pill" id="pills-inactivos-tab" data-bs-toggle="pill" data-bs-target="#inactivos"
        type="button" role="tab" aria-selected="false" (click)="cambio()">Dañados</button>
    </li>
  </ul>

  <section style="background-color: white; overflow: scroll;">
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane show active" id="activos" role="tabpanel" style="padding-left: 2vh; padding-right: 2vh;">
        <div style="justify-content: right; display: flex; width: 100%;">
          <select name="filtro" id="filtro" #cbCampo
            style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
            <option selected value="DUENO">Dueño</option>
            <option value="DOMICILIO">Domicilio</option>
          </select>
          <input type="text" style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px;"
            placeholder="Ingrese su busqueda" (keyup)="buscar()" #ctCadena>
          <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
            <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscando === false"></i>
            <div class="d-flex justify-content-center" *ngIf="this.buscando === true">
              <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
            </div>
          </button>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh;">
          <thead style="color: #C8102E;">
            <th scope="col">Dueño</th>
            <th scope="col">Domicilio</th>
            <th scope="col">Fecha</th>
            <th scope="col"></th>
          </thead>
          <tbody *ngIf="this.cargando === false">
            <tr *ngFor="let dato of intactos | paginate: { itemsPerPage: 10, currentPage: pages, id:'activos' }">
              <td>{{dato.DUENO}}</td>
              <td>{{dato.DOMICILIO}}</td>
              <td>{{dato.FECHA}}</td>
              <td><button class="accion" (click)="ver(dato.ID)"><i class="fa fa-search"></i>
                  Ver</button></td>
            </tr>
            <tr *ngIf="this.noEncontrado === true">
              <td style="justify-content: center; text-align: center;" colspan="4"><strong>No se encontro ningun
                  registro con ese dato</strong></td>
            </tr>
            <tr *ngIf="this.intactasLength === 0 && this.noEncontrado === false">
              <td style="justify-content: center; text-align: center;" colspan="4"><strong>Sin registros</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargando === true">
            <td colspan="4">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #C8102E;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="activos" class="my-pagination" (pageChange)="pages = $event" previousLabel="Anterior"
            nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>


      <div class="tab-pane" id="inactivos" role="tabpanel" style="padding-left: 2vh; padding-right: 2vh;">
        <div style="justify-content: right; display: flex; width: 100%;">
          <select name="filtro" id="filtro" #cbCampoIn
            style="border-top-left-radius: 7px; border-bottom-left-radius: 7px; height: 30px; margin-top: 2vh; border-color: black;">
            <option selected value="DUENO">Dueño</option>
            <option value="DOMICILIO">Domicilio</option>
          </select>
          <input type="text" style="height: 30px; width: 300px; margin-top: 2vh; border-width: 2px;"
            placeholder="Ingrese su busqueda" (keyup)="buscarIn()" #ctCadenaIn>
          <button disabled class="botonBuscar" style="margin-top: 2vh; margin-right: 4vh;">
            <i class="fa fa-search" aria-hidden="true" *ngIf="this.buscandoIn === false"></i>
            <div class="d-flex justify-content-center" *ngIf="this.buscandoIn === true">
              <div class="spinner-border" style="color: #ffffff; height: 18px; width: 18px;"></div>
            </div>
          </button>
        </div>
        <table class="table animate__fadeIn animate__animated" style="margin-top: 2vh; padding: 2vh;">
          <thead style="color: #C8102E;">
            <th scope="col">Dueño</th>
            <th scope="col">Domicilio</th>
            <th scope="col">Fecha</th>
            <th scope="col"></th>
          </thead>
          <tbody *ngIf="this.cargandoIn === false">
            <tr *ngFor="let dato of danados | paginate: { itemsPerPage: 10, currentPage: pages, id:'inactivos' }">
              <td>{{dato.DUENO}}</td>
              <td>{{dato.DOMICILIO}}</td>
              <td>{{dato.FECHA}}</td>
              <td><button class="accion" (click)="ver(dato.ID)"><i class="fa fa-search"></i>
                  Ver</button></td>
            </tr>
            <tr>
              <td *ngIf="this.noEncontradoIn === true" style="justify-content: center; text-align: center;" colspan="4">
                <strong>No se encontro ningun
                  registro con ese dato</strong>
              </td>
            </tr>
            <tr *ngIf="this.danadasLength === 0 && this.noEncontradoIn === false">
              <td style="justify-content: center; text-align: center;" colspan="4"><strong>Sin registros</strong></td>
            </tr>
          </tbody>
          <tr *ngIf="this.cargandoIn === true">
            <td colspan="4">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" style="color: #C8102E;"></div>
              </div>
            </td>
          </tr>
        </table>
        <div class="d-flex justify-content-center">
          <pagination-controls id="inactivos" class="my-pagination" (pageChange)="pages = $event"
            previousLabel="Anterior" nextLabel="Siguiente"></pagination-controls>
        </div>
      </div>
    </div>
    <div style="justify-content: center; text-align: center;">
      <button class="boton" (click)="regresar()">Regresar</button>
    </div>
  </section>
</div>



<!-- Inicio Modal Ver -->
<dialog id="modalVer" style="border: none; border-radius: 1vh; width: 60%;" class="animate__zoomIn animate__animated">

  <div class="row" style="justify-content: right; display: flex;">
    <button class="botonCerrar" (click)="cerrarModalVer()"><i class="fa fa-times" style="font-size: 20px;"></i></button>
  </div>

  <div style="justify-content: center; text-align: center; align-items: center; display: flex; margin-top: 2vh;"
    class="row">
    <h2>Ver Registro</h2>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row"
    *ngIf="spinner === false">

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 2vh; margin-left: 1vh; width: 100%;"><strong>Dueño:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" disabled [(ngModel)]="duenoEs">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10">
      <label style="margin-top: 1vh; margin-left: 1vh; width: 100%;"><strong>Domicilio:</strong>
        <input type="text" class="form-control" aria-describedby="basic-addon1" disabled [(ngModel)]="domicilioEs">
      </label>
    </div>
    <div class="col-1"></div>

    <div class="col-1"></div>
    <div class="col-10" style="justify-content: center; display: flex;">
      <img [src]="this.imgEs" style="width: 50%;">
    </div>
    <div class="col-1"></div>
  </div>

  <div style="justify-content: center; align-items: center; display: flex; margin-top: 1vh;" class="row"
    *ngIf="spinner === true">
    <div class="d-flex justify-content-center" style="margin-top: 1vh;">
      <div class="spinner-border" style="color: #C8102E;"></div>
    </div>
  </div>

</dialog>
<!-- Final Modal Ver -->
