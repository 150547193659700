import { Component } from '@angular/core';
import * as L from 'leaflet';
import { GeneralService } from 'src/services/general.service';

@Component({
  selector: 'app-ubicaciones',
  templateUrl: './ubicaciones.component.html',
  styleUrls: ['./ubicaciones.component.scss']
})
export class UbicacionesComponent {

  map: L.Map;
  cargando: any;
  estado: any;
  letreros: any;
  iconMarker: any;

  constructor(private generalService: GeneralService) { }

  ngOnInit() {
    this.cargando = true;
    this.estado = true;
    this.cambioL();
  }

  cambioLp(event: any) {
    this.estado = event.target.checked;
    console.log(this.estado);
    if (this.estado === false) {
      this.generalService.obternerLista(1, 0).subscribe(res => {
        this.letreros = res.body;
        console.log(this.letreros);
        this.Map1();
      })
    } else if (this.estado === true) {
      this.generalService.obternerLista(1, 1).subscribe(res => {
        this.letreros = res.body;
        console.log(this.letreros);
        this.Map1();
      })
    }
  }

  cambioL() {
    this.generalService.obternerLista(1, 1).subscribe(res => {
      this.letreros = res.body;
      console.log(this.letreros);
      this.Map1();
    })
  }

  Map1() {

    if (this.map) {
      this.map.off();
      this.map.remove();
    }

    this.map = L.map('map', {
      center: [19.7682200, -104.3666400],
      zoom: 10,
      minZoom: 10,
      fadeAnimation: true,
      markerZoomAnimation: true,
    })

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(this.map);

    this.iconMarker = L.icon({
      iconUrl: 'https://i.imgur.com/eKZmgpc.png',
      iconSize: [30, 30],
      iconAnchor: [10, 10],
    });

    for (let i = 0; i < this.letreros.length; i++) {
      L.marker([this.letreros[i].UBICACION_LAT, this.letreros[i].UBICACION_LON], { icon: this.iconMarker }).bindPopup('<p style="text-align: center">' + this.letreros[i].DOMICILIO + '<br><br>' + '<img style="width: 50%;;" src="' + this.letreros[i].IMAGEN + ' " /><p/>', {
        maxWidth: 500, minWidth: 500
      }).openPopup().addTo(this.map);
    }

    var polygon1 = L.polygon([
      [19.8877027, -104.4213259],
      [19.8726429, -104.4078827],
      [19.8642478, -104.3982697],
      [19.8577898, -104.3907166],
      [19.8493940, -104.3859100],
      [19.8384141, -104.3838501],
      [19.8300173, -104.3824768],
      [19.8261416, -104.3756104],
      [19.8267876, -104.3687439],
      [19.8300173, -104.3632507],
      [19.8203280, -104.3543243],
      [19.8132222, -104.3495178],
      [19.8158062, -104.3385315],
      [19.8190361, -104.3316650],
      [19.8170981, -104.3186188],
      [19.8119302, -104.3069458],
      [19.8067621, -104.2993927],
      [19.8054700, -104.2904663],
      [19.8067621, -104.2815399],
      [19.8125762, -104.2760468],
      [19.8190361, -104.2712402],
      [19.8293713, -104.2938995],
      [19.8371223, -104.2938995],
      [19.8390600, -104.3035126],
      [19.8474564, -104.3131256],
      [19.8519773, -104.3048859],
      [19.8614628, -104.2990065],
      [19.8619472, -104.3020535],
      [19.8634406, -104.3045425],
      [19.8652972, -104.3060875],
      [19.8683243, -104.3068171],
      [19.8711899, -104.3082333],
      [19.8703020, -104.3120098],
      [19.8711899, -104.3168163],
      [19.8718357, -104.3187046],
      [19.8732886, -104.3197346],
      [19.8767595, -104.3213654],
      [19.8775667, -104.3240261],
      [19.8766788, -104.3287468],
      [19.8775667, -104.3314934],
      [19.8803111, -104.3323517],
      [19.8851539, -104.3335533],
      [19.8812796, -104.3395615],
      [19.8828132, -104.3418789],
      [19.8868489, -104.3406773],
      [19.8894317, -104.3458271],
      [19.8887053, -104.3505478],
      [19.8914494, -104.3538094],
      [19.8969376, -104.3559551],
      [19.9018607, -104.3551826],
      [19.9040397, -104.3563843],
      [19.9051695, -104.3628216],
      [19.9082362, -104.3642807],
      [19.9129975, -104.3622208],
      [19.9181218, -104.3627357],
      [19.9201795, -104.3619204],
      [19.9230039, -104.3630791],
      [19.9259492, -104.3677998],
      [19.9244967, -104.3711901],
      [19.9232459, -104.3714046],
      [19.9221969, -104.3722200],
      [19.9220759, -104.3726063],
      [19.9198971, -104.3734646],
      [19.9189691, -104.3747091],
      [19.9178797, -104.3753529],
      [19.9171131, -104.3769407],
      [19.9164272, -104.3787432],
      [19.9155395, -104.3804598],
      [19.9156202, -104.3813181],
      [19.9172341, -104.3818331],
      [19.9175973, -104.3832922],
      [19.9160640, -104.3871546],
      [19.9182025, -104.3895149],
      [19.9213496, -104.3906307],
      [19.9213900, -104.3920040],
      [19.9209058, -104.3923903],
      [19.9194533, -104.3922186],
      [19.9191305, -104.3930340],
      [19.9196147, -104.3944502],
      [19.9185656, -104.3955660],
      [19.9170727, -104.3963385],
      [19.9187270, -104.3991709],
      [19.9205830, -104.4007158],
      [19.9209058, -104.4023895],
      [19.9196147, -104.4023037],
      [19.9184042, -104.4019175],
      [19.9171938, -104.4023466],
      [19.9165079, -104.4035912],
      [19.9163465, -104.4043207],
      [19.9175569, -104.4041491],
      [19.9184042, -104.4044495],
      [19.9182428, -104.4051361],
      [19.9180815, -104.4057798],
      [19.9175973, -104.4060802],
      [19.9160237, -104.4070244],
      [19.9154991, -104.4085264],
      [19.9178797, -104.4089556],
      [19.9194533, -104.4095135],
      [19.9193322, -104.4108438],
      [19.9180411, -104.4120884],
      [19.9177990, -104.4131184],
      [19.9169920, -104.4130754],
      [19.9165079, -104.4126892],
      [19.9152974, -104.4133759],
      [19.9142887, -104.4145775],
      [19.9138448, -104.4173670],
      [19.9127151, -104.4165087],
      [19.9118274, -104.4170666],
      [19.9107783, -104.4171095],
      [19.9089625, -104.4186974],
      [19.9061379, -104.4164228],
      [19.9050081, -104.4158220],
      [19.9042414, -104.4164228],
      [19.9029905, -104.4172812],
      [19.9033133, -104.4177961],
      [19.9026273, -104.4187403],
      [19.9028291, -104.4199419],
      [19.9023045, -104.4206715],
      [19.9012957, -104.4204998],
      [19.8996413, -104.4216156],
      [19.8979868, -104.4200706],
      [19.8964937, -104.4195986],
      [19.8953234, -104.4211435],
      [19.8951217, -104.4217443],
      [19.8942742, -104.4218731],
      [19.8935882, -104.4223452],
      [19.8921758, -104.4216585],
      [19.8912880, -104.4202423],
      [19.8902388, -104.4208431],
      [19.8896334, -104.4207573],
      [19.8891895, -104.4213581],
      [19.8885741, -104.4210792],
      [19.8877039, -104.4213246]
    ]).addTo(this.map);

    var polygon2 = L.polygon([
      [19.8015938, -104.4703674],
      [19.7970714, -104.4600677],
      [19.7931950, -104.4525146],
      [19.7873802, -104.4456482],
      [19.7957793, -104.4401550],
      [19.7931950, -104.4319153],
      [19.8048240, -104.4367218],
      [19.7990096, -104.4236755],
      [19.7912568, -104.4126892],
      [19.7854419, -104.3982697],
      [19.7893185, -104.3845367],
      [19.7828574, -104.3783569],
      [19.7912568, -104.3728638],
      [19.7951332, -104.3783569],
      [19.7977175, -104.3708038],
      [19.8054700, -104.3817902],
      [19.8138682, -104.3797302],
      [19.8119302, -104.3742371],
      [19.8270399, -104.3771875],
      [19.8279382, -104.3787754],
      [19.8300072, -104.3824875],
      [19.8397866, -104.3841076],
      [19.8494343, -104.3859100],
      [19.8531479, -104.3880558],
      [19.8578100, -104.3907166],
      [19.8726832, -104.4079685],
      [19.8877020, -104.4213266],
      [19.8853961, -104.4231176],
      [19.8858400, -104.4259071],
      [19.8857593, -104.4285679],
      [19.8852346, -104.4296837],
      [19.8857996, -104.4322586],
      [19.8851943, -104.4344902],
      [19.8854768, -104.4362068],
      [19.8851136, -104.4368935],
      [19.8834589, -104.4380951],
      [19.8814007, -104.4383097],
      [19.8763559, -104.4412708],
      [19.8751452, -104.4446182],
      [19.8715935, -104.4443607],
      [19.8697370, -104.4462490],
      [19.8678804, -104.4471931],
      [19.8662659, -104.4465065],
      [19.8651358, -104.4477940],
      [19.8623912, -104.4481373],
      [19.8598080, -104.4477081],
      [19.8585971, -104.4492531],
      [19.8568211, -104.4498539],
      [19.8555294, -104.4495964],
      [19.8543992, -104.4503689],
      [19.8517352, -104.4524288],
      [19.8488288, -104.4534588],
      [19.8467298, -104.4557762],
      [19.8460032, -104.4526863],
      [19.8422087, -104.4487381],
      [19.8393022, -104.4495106],
      [19.8369608, -104.4483089],
      [19.8367994, -104.4471073],
      [19.8351039, -104.4474506],
      [19.8320358, -104.4460773],
      [19.8305824, -104.4428158],
      [19.8283217, -104.4400692],
      [19.8267876, -104.4392109],
      [19.8267876, -104.4374084],
      [19.8240423, -104.4362068],
      [19.8260609, -104.4398117],
      [19.8254149, -104.4411850],
      [19.8253342, -104.4425583],
      [19.8245268, -104.4438457],
      [19.8251727, -104.4456482],
      [19.8251727, -104.4471073],
      [19.8254957, -104.4507122],
      [19.8252534, -104.4543171],
      [19.8256572, -104.4614410],
      [19.8250112, -104.4627285],
      [19.8172596, -104.4641018],
      [19.8030474, -104.4719124],
      [19.8015938, -104.4703889]
    ]).addTo(this.map);

    var polygon3 = L.polygon([
      [19.8064290, -104.2971289],
      [19.7970714, -104.3000793],
      [19.7938411, -104.3055725],
      [19.7906107, -104.2952728],
      [19.7860880, -104.2815399],
      [19.7860880, -104.2684937],
      [19.7893185, -104.2561340],
      [19.7944872, -104.2485809],
      [19.8012708, -104.2387962],
      [19.8066813, -104.2407703],
      [19.8102344, -104.2398262],
      [19.8128992, -104.2408562],
      [19.8158869, -104.2411995],
      [19.8177441, -104.2426586],
      [19.8198435, -104.2431736],
      [19.8206510, -104.2407703],
      [19.8226696, -104.2425728],
      [19.8238001, -104.2419720],
      [19.8247690, -104.2435169],
      [19.8238808, -104.2449760],
      [19.8243653, -104.2455769],
      [19.8227504, -104.2467785],
      [19.8232348, -104.2493534],
      [19.8251727, -104.2514133],
      [19.8275950, -104.2563915],
      [19.8252534, -104.2653179],
      [19.8242845, -104.2724419],
      [19.8233963, -104.2745876],
      [19.8249305, -104.2769051],
      [19.8269491, -104.2805958],
      [19.8304210, -104.2817974],
      [19.8306632, -104.2827415],
      [19.8343772, -104.2832565],
      [19.8363149, -104.2823124],
      [19.8405940, -104.2817116],
      [19.8449537, -104.2830849],
      [19.8492325, -104.2826557],
      [19.8527039, -104.2853165],
      [19.8549643, -104.2884064],
      [19.8582742, -104.2908955],
      [19.8602116, -104.2949295],
      [19.8614679, -104.2990226],
      [19.8565789, -104.3019676],
      [19.8519370, -104.3050146],
      [19.8475775, -104.3130398],
      [19.8390196, -104.3035984],
      [19.8371627, -104.2939425],
      [19.8293310, -104.2938995],
      [19.8189957, -104.2711973],
      [19.8123339, -104.2761755],
      [19.8067217, -104.2814112],
      [19.8054700, -104.2904234],
      [19.8064290, -104.2971396]
    ]).addTo(this.map);

    var polygon4 = L.polygon([
      [19.7589491, -104.4250059],
      [19.7628264, -104.4120026],
      [19.7634726, -104.4044495],
      [19.7602416, -104.3975830],
      [19.7647650, -104.3920898],
      [19.7662189, -104.3874550],
      [19.7665420, -104.3847084],
      [19.7692883, -104.3797302],
      [19.7738114, -104.3804169],
      [19.7755884, -104.3788719],
      [19.7775268, -104.3785286],
      [19.7767191, -104.3744087],
      [19.7773653, -104.3725204],
      [19.7783345, -104.3721771],
      [19.7797883, -104.3690872],
      [19.7912164, -104.3728852],
      [19.7828978, -104.3784428],
      [19.7893589, -104.3845797],
      [19.7854015, -104.3983555],
      [19.7883090, -104.4053936],
      [19.7910952, -104.4126463],
      [19.7987673, -104.4236755],
      [19.8046625, -104.4364643],
      [19.7930335, -104.4319153],
      [19.7958601, -104.4403267],
      [19.7872187, -104.4457340],
      [19.7929527, -104.4526863],
      [19.7974752, -104.4613552],
      [19.8013919, -104.4702172],
      [19.8029666, -104.4719338],
      [19.8004834, -104.4733500],
      [19.7996960, -104.4735861],
      [19.7985251, -104.4724059],
      [19.7953957, -104.4715905],
      [19.7931950, -104.4727707],
      [19.7913577, -104.4731784],
      [19.7893992, -104.4720411],
      [19.7890964, -104.4715905],
      [19.7883695, -104.4719338],
      [19.7860274, -104.4708610],
      [19.7850784, -104.4716763],
      [19.7832410, -104.4716120],
      [19.7828978, -104.4719768],
      [19.7826151, -104.4714403],
      [19.7819488, -104.4715047],
      [19.7818276, -104.4712901],
      [19.7806363, -104.4716549],
      [19.7793643, -104.4714189],
      [19.7782133, -104.4709468],
      [19.7778903, -104.4702172],
      [19.7779710, -104.4693160],
      [19.7774915, -104.4689405],
      [19.7750634, -104.4645309],
      [19.7723980, -104.4632006],
      [19.7664612, -104.4594669],
      [19.7670266, -104.4586086],
      [19.7599992, -104.4563770],
      [19.7582221, -104.4532013],
      [19.7593530, -104.4496822],
      [19.7575759, -104.4455624],
      [19.7584645, -104.4414425],
      [19.7561219, -104.4384384],
      [19.7566873, -104.4354343],
      [19.7562835, -104.4338036],
      [19.7579798, -104.4315720],
      [19.7577375, -104.4286537],
      [19.7592722, -104.4272804],
      [19.7589491, -104.4249845]
    ]).addTo(this.map);

    var polygon5 = L.polygon([
      [19.7474581, -104.1931558],
      [19.7350373, -104.2025757],
      [19.7402077, -104.2163086],
      [19.7298667, -104.2224884],
      [19.7253422, -104.2101288],
      [19.7234032, -104.1977692],
      [19.7091826, -104.2039490],
      [19.7137075, -104.2135620],
      [19.7150002, -104.2197418],
      [19.7156466, -104.2300415],
      [19.7143539, -104.2375946],
      [19.7020718, -104.2396545],
      [19.6949607, -104.2478943],
      [19.6800911, -104.2602539],
      [19.6749187, -104.2726994],
      [19.6640076, -104.2746735],
      [19.6612192, -104.2734289],
      [19.6580265, -104.2719269],
      [19.6446084, -104.2733860],
      [19.6250452, -104.2794800],
      [19.6194668, -104.2903805],
      [19.6180115, -104.2981052],
      [19.5984450, -104.2958307],
      [19.5986269, -104.2943931],
      [19.5982631, -104.2934489],
      [19.5967672, -104.2924619],
      [19.5963427, -104.2929339],
      [19.5956099, -104.2915446],
      [19.5952915, -104.2899299],
      [19.5950388, -104.2884815],
      [19.5954128, -104.2873335],
      [19.5949478, -104.2872906],
      [19.5945638, -104.2875695],
      [19.5946446, -104.2881918],
      [19.5942605, -104.2881274],
      [19.5939472, -104.2866683],
      [19.5940078, -104.2837501],
      [19.5938765, -104.2829025],
      [19.5932195, -104.2818940],
      [19.5932296, -104.2811859],
      [19.5925119, -104.2806602],
      [19.5911373, -104.2786860],
      [19.5884991, -104.2754459],
      [19.5885800, -104.2719269],
      [19.5884991, -104.2688370],
      [19.5877714, -104.2669487],
      [19.5887417, -104.2632580],
      [19.5919762, -104.2621422],
      [19.5936743, -104.2620564],
      [19.5946446, -104.2606831],
      [19.5937552, -104.2578506],
      [19.5944020, -104.2564774],
      [19.5948872, -104.2553616],
      [19.5931891, -104.2527008],
      [19.5943212, -104.2510700],
      [19.5937552, -104.2448902],
      [19.5967874, -104.2435598],
      [19.5967874, -104.2407703],
      [19.5968683, -104.2393970],
      [19.5965448, -104.2381525],
      [19.5977577, -104.2352343],
      [19.5965853, -104.2320585],
      [19.5977981, -104.2286682],
      [19.5949276, -104.2263937],
      [19.5937956, -104.2260075],
      [19.5927444, -104.2231750],
      [19.5919358, -104.2231750],
      [19.5923401, -104.2237329],
      [19.5916932, -104.2246342],
      [19.5907633, -104.2240763],
      [19.5910867, -104.2217588],
      [19.5935126, -104.2214584],
      [19.5942403, -104.2207289],
      [19.5933509, -104.2178535],
      [19.5939977, -104.2167377],
      [19.5933509, -104.2158365],
      [19.5941595, -104.2148495],
      [19.5967065, -104.2142916],
      [19.5988089, -104.2103004],
      [19.5994557, -104.2069530],
      [19.6011133, -104.2058802],
      [19.6026900, -104.2046785],
      [19.6056817, -104.2046785],
      [19.6064093, -104.2030907],
      [19.6078647, -104.2029619],
      [19.6081477, -104.2022324],
      [19.6115435, -104.2009020],
      [19.6119882, -104.2001295],
      [19.6134839, -104.1994858],
      [19.6140095, -104.2000866],
      [19.6146967, -104.2008162],
      [19.6156669, -104.2006016],
      [19.6172030, -104.2036486],
      [19.6181732, -104.2035627],
      [19.6197093, -104.2024040],
      [19.6191030, -104.2006445],
      [19.6181328, -104.1991854],
      [19.6222964, -104.1944647],
      [19.6229028, -104.1922760],
      [19.6220943, -104.1908169],
      [19.6226602, -104.1869545],
      [19.6262983, -104.1838217],
      [19.6276726, -104.1840363],
      [19.6284002, -104.1832638],
      [19.6301383, -104.1829205],
      [19.6321998, -104.1785860],
      [19.6343825, -104.1787148],
      [19.6367268, -104.1768694],
      [19.6392732, -104.1758394],
      [19.6400816, -104.1760111],
      [19.6410516, -104.1753674],
      [19.6431938, -104.1750669],
      [19.6433555, -104.1741228],
      [19.6467505, -104.1708183],
      [19.6480439, -104.1711187],
      [19.6485693, -104.1720629],
      [19.6493372, -104.1719341],
      [19.6515197, -104.1712475],
      [19.6526513, -104.1694021],
      [19.6543083, -104.1654968],
      [19.6554399, -104.1643381],
      [19.6551975, -104.1627073],
      [19.6559249, -104.1621494],
      [19.6575011, -104.1625786],
      [19.6578244, -104.1615057],
      [19.6589156, -104.1607332],
      [19.6602088, -104.1609907],
      [19.6606130, -104.1595316],
      [19.6609969, -104.1578150],
      [19.6620678, -104.1568708],
      [19.6633004, -104.1574502],
      [19.6640885, -104.1583729],
      [19.6646542, -104.1584373],
      [19.6652200, -104.1582441],
      [19.6652200, -104.1576862],
      [19.6649169, -104.1572142],
      [19.6654019, -104.1561198],
      [19.6681903, -104.1522145],
      [19.6695239, -104.1510129],
      [19.6700896, -104.1497040],
      [19.6701705, -104.1485238],
      [19.6766967, -104.1436958],
      [19.6918091, -104.1378593],
      [19.6902737, -104.1473866],
      [19.6928597, -104.1491890],
      [19.6966577, -104.1494465],
      [19.6985163, -104.1426659],
      [19.7055464, -104.1437817],
      [19.7071625, -104.1460133],
      [19.7065161, -104.1492748],
      [19.7056171, -104.1514421],
      [19.7055262, -104.1532338],
      [19.7056979, -104.1537058],
      [19.7060818, -104.1540813],
      [19.7078897, -104.1539097],
      [19.7091018, -104.1526222],
      [19.7104754, -104.1522789],
      [19.7116066, -104.1530514],
      [19.7143539, -104.1539955],
      [19.7158082, -104.1533089],
      [19.7167778, -104.1533089],
      [19.7161314, -104.1561413],
      [19.7224336, -104.1600895],
      [19.7272813, -104.1583729],
      [19.7311593, -104.1604328],
      [19.7330983, -104.1613770],
      [19.7365723, -104.1636944],
      [19.7347141, -104.1658401],
      [19.7322096, -104.1662693],
      [19.7322904, -104.1686726],
      [19.7331791, -104.1694450],
      [19.7335023, -104.1709042],
      [19.7326136, -104.1721916],
      [19.7337446, -104.1732216],
      [19.7348757, -104.1749382],
      [19.7385112, -104.1739941],
      [19.7408540, -104.1739941],
      [19.7415003, -104.1755390],
      [19.7419446, -104.1788006],
      [19.7442470, -104.1809034],
      [19.7417830, -104.1813755],
      [19.7394402, -104.1797018],
      [19.7389959, -104.1806030],
      [19.7392786, -104.1830063],
      [19.7395210, -104.1848946],
      [19.7423485, -104.1870403],
      [19.7454184, -104.1863966],
      [19.7476399, -104.1874266],
      [19.7482862, -104.1900015],
      [19.7478419, -104.1908169],
      [19.7465897, -104.1907740],
      [19.7461050, -104.1912889],
      [19.7468321, -104.1929090],
      [19.7474581, -104.1931343]
    ]).addTo(this.map);

    var polygon6 = L.polygon([
      [19.5983641, -104.2958844],
      [19.6180115, -104.2980194],
      [19.6193051, -104.2904663],
      [19.6251260, -104.2794800],
      [19.6341804, -104.2767334],
      [19.6445276, -104.2733002],
      [19.6581073, -104.2719269],
      [19.6639268, -104.2746735],
      [19.6749187, -104.2726135],
      [19.6833237, -104.2884064],
      [19.7027183, -104.2904663],
      [19.7072433, -104.3028259],
      [19.7156466, -104.3076324],
      [19.7124146, -104.3110657],
      [19.7175858, -104.3138123],
      [19.7195250, -104.3199921],
      [19.7175757, -104.3282533],
      [19.7137075, -104.3323088],
      [19.7169394, -104.3400335],
      [19.7150002, -104.3467712],
      [19.7078493, -104.3502045],
      [19.7033353, -104.3555260],
      [19.7024758, -104.3605042],
      [19.7026374, -104.3658257],
      [19.7073241, -104.3724346],
      [19.7091826, -104.3785286],
      [19.7149901, -104.3893272],
      [19.7154901, -104.3924546],
      [19.7152275, -104.3926799],
      [19.7146619, -104.3923581],
      [19.7144751, -104.3915588],
      [19.7144448, -104.3907326],
      [19.7140812, -104.3902552],
      [19.7134651, -104.3902445],
      [19.7128237, -104.3902981],
      [19.7119298, -104.3895578],
      [19.7111420, -104.3878412],
      [19.7107178, -104.3865967],
      [19.7090412, -104.3865752],
      [19.7070817, -104.3861890],
      [19.7065161, -104.3862534],
      [19.7051222, -104.3862963],
      [19.7038495, -104.3874121],
      [19.7011829, -104.3879485],
      [19.6995668, -104.3865323],
      [19.6979506, -104.3869400],
      [19.6976274, -104.3853092],
      [19.6979102, -104.3843222],
      [19.6960921, -104.3835497],
      [19.6954052, -104.3805027],
      [19.6934253, -104.3789577],
      [19.6939102, -104.3780565],
      [19.6922940, -104.3768978],
      [19.6911626, -104.3750525],
      [19.6906373, -104.3750525],
      [19.6907182, -104.3720913],
      [19.6901929, -104.3712759],
      [19.6900717, -104.3703747],
      [19.6904353, -104.3696022],
      [19.6897080, -104.3685293],
      [19.6885766, -104.3692160],
      [19.6843743, -104.3714905],
      [19.6835258, -104.3723488],
      [19.6792021, -104.3745375],
      [19.6745954, -104.3790436],
      [19.6728174, -104.3768120],
      [19.6709989, -104.3783569],
      [19.6682913, -104.3791294],
      [19.6679680, -104.3784857],
      [19.6674427, -104.3787003],
      [19.6675235, -104.3792582],
      [19.6666749, -104.3798161],
      [19.6665132, -104.3789148],
      [19.6652200, -104.3791723],
      [19.6646947, -104.3782282],
      [19.6629973, -104.3789148],
      [19.6619466, -104.3789148],
      [19.6614212, -104.3796444],
      [19.6602896, -104.3785286],
      [19.6586731, -104.3747520],
      [19.6582285, -104.3760395],
      [19.6573394, -104.3760395],
      [19.6566524, -104.3768978],
      [19.6558441, -104.3774557],
      [19.6548741, -104.3776274],
      [19.6543892, -104.3768978],
      [19.6528938, -104.3762970],
      [19.6528534, -104.3756104],
      [19.6517217, -104.3764687],
      [19.6506709, -104.3761253],
      [19.6506709, -104.3752670],
      [19.6491351, -104.3754387],
      [19.6488118, -104.3746233],
      [19.6484076, -104.3746662],
      [19.6483672, -104.3751812],
      [19.6479226, -104.3756104],
      [19.6470335, -104.3758249],
      [19.6459018, -104.3741941],
      [19.6442447, -104.3748379],
      [19.6364237, -104.3726277],
      [19.6312297, -104.3768549],
      [19.6265408, -104.3824768],
      [19.6264599, -104.3902016],
      [19.6245197, -104.3875408],
      [19.6228623, -104.3830347],
      [19.6209624, -104.3794727],
      [19.6198710, -104.3781424],
      [19.6196487, -104.3762541],
      [19.6183955, -104.3753099],
      [19.6175264, -104.3633366],
      [19.6155052, -104.3608475],
      [19.6138073, -104.3512344],
      [19.6074200, -104.3496037],
      [19.6062881, -104.3484879],
      [19.6031347, -104.3477154],
      [19.6011133, -104.3492603],
      [19.6009516, -104.3435955],
      [19.5994962, -104.3425655],
      [19.6006282, -104.3417931],
      [19.6005473, -104.3403339],
      [19.6026496, -104.3420506],
      [19.6028113, -104.3401623],
      [19.6042667, -104.3367290],
      [19.6071775, -104.3342400],
      [19.6072179, -104.3317509],
      [19.6062881, -104.3335962],
      [19.6049944, -104.3345404],
      [19.6042667, -104.3343687],
      [19.6058838, -104.3282318],
      [19.6055604, -104.3267727],
      [19.6049135, -104.3267298],
      [19.6037007, -104.3257856],
      [19.6046305, -104.3233824],
      [19.6056008, -104.3221378],
      [19.6048327, -104.3219233],
      [19.6041454, -104.3226957],
      [19.6034986, -104.3225670],
      [19.6041858, -104.3212366],
      [19.6036603, -104.3207645],
      [19.6020027, -104.3217945],
      [19.6015580, -104.3226528],
      [19.6000622, -104.3231678],
      [19.6002239, -104.3214083],
      [19.6010324, -104.3209791],
      [19.6034581, -104.3152285],
      [19.6067732, -104.3103790],
      [19.6061668, -104.3078899],
      [19.6059242, -104.3054867],
      [19.6047518, -104.3037701],
      [19.6041858, -104.3030405],
      [19.6034986, -104.3011522],
      [19.6040241, -104.2992640],
      [19.6018006, -104.2983198],
      [19.6010324, -104.2982340],
      [19.5999004, -104.2973328],
      [19.5985259, -104.2972040],
      [19.5977981, -104.2974186],
      [19.5978689, -104.2966783],
      [19.5983641, -104.2958844]
    ]).addTo(this.map);

    var polygon7 = L.polygon([
      [19.7663249, -104.3865323],
      [19.7637957, -104.3835068],
      [19.7604031, -104.3855667],
      [19.7589491, -104.3828201],
      [19.7542355, -104.3835068],
      [19.7537793, -104.3817902],
      [19.7541452, -104.3745804],
      [19.7570105, -104.3708038],
      [19.7518406, -104.3666840],
      [19.7589491, -104.3646240],
      [19.7537793, -104.3584442],
      [19.7479630, -104.3529510],
      [19.7402077, -104.3515778],
      [19.7343909, -104.3556976],
      [19.7343909, -104.3460846],
      [19.7382688, -104.3419647],
      [19.7408540, -104.3364716],
      [19.7324520, -104.3350983],
      [19.7259886, -104.3357849],
      [19.7272813, -104.3296051],
      [19.7175858, -104.3282318],
      [19.7137075, -104.3323517],
      [19.7169394, -104.3399048],
      [19.7150002, -104.3467712],
      [19.7078897, -104.3502045],
      [19.7033647, -104.3556976],
      [19.7027183, -104.3605042],
      [19.7027183, -104.3659973],
      [19.7072433, -104.3721771],
      [19.7091826, -104.3783569],
      [19.7150002, -104.3893433],
      [19.7154850, -104.3924332],
      [19.7181514, -104.3847084],
      [19.7221912, -104.3829060],
      [19.7266350, -104.3830776],
      [19.7291395, -104.3839359],
      [19.7297859, -104.3824768],
      [19.7300282, -104.3799877],
      [19.7322096, -104.3778419],
      [19.7336234, -104.3756104],
      [19.7329367, -104.3747950],
      [19.7330175, -104.3731213],
      [19.7361683, -104.3730354],
      [19.7400461, -104.3706322],
      [19.7414195, -104.3708897],
      [19.7404501, -104.3738079],
      [19.7413387, -104.3740654],
      [19.7440046, -104.3728638],
      [19.7415811, -104.3848801],
      [19.7358452, -104.3782711],
      [19.7356028, -104.3794727],
      [19.7372994, -104.3815327],
      [19.7373237, -104.3847084],
      [19.7393998, -104.3880558],
      [19.7388343, -104.3901157],
      [19.7354412, -104.3930340],
      [19.7356028, -104.3968964],
      [19.7331791, -104.3999863],
      [19.7330983, -104.4018745],
      [19.7312401, -104.4050503],
      [19.7326136, -104.4082260],
      [19.7385920, -104.4160366],
      [19.7431968, -104.4243622],
      [19.7523253, -104.4265079],
      [19.7589542, -104.4250059],
      [19.7628264, -104.4121742],
      [19.7635130, -104.4045353],
      [19.7601608, -104.3976259],
      [19.7647246, -104.3921757],
      [19.7662189, -104.3874711],
      [19.7662997, -104.3867522],
      [19.7663300, -104.3865484],
    ]).addTo(this.map);

    var polygon8 = L.polygon([
      [19.7901034, -104.2256293],
      [19.7899646, -104.2417145],
      [19.7828574, -104.2417145],
      [19.7809190, -104.2458344],
      [19.7751038, -104.2430878],
      [19.7738114, -104.2478943],
      [19.7783345, -104.2540741],
      [19.7763961, -104.2602539],
      [19.7692883, -104.2636871],
      [19.7673497, -104.2712402],
      [19.7641188, -104.2753601],
      [19.7673497, -104.2815399],
      [19.7679959, -104.2890930],
      [19.7628264, -104.2918396],
      [19.7563642, -104.2897797],
      [19.7602416, -104.3000793],
      [19.7550718, -104.3028259],
      [19.7647650, -104.3172455],
      [19.7647650, -104.3275452],
      [19.7718730, -104.3268585],
      [19.7763961, -104.3206787],
      [19.7751038, -104.3103790],
      [19.7860880, -104.3138123],
      [19.7938411, -104.3056154],
      [19.7861687, -104.2818832],
      [19.7860880, -104.2686653],
      [19.7892377, -104.2562628],
      [19.8013087, -104.2387104],
      [19.7989289, -104.2357814],
      [19.7975762, -104.2359316],
      [19.7967080, -104.2345154],
      [19.7964960, -104.2344081],
      [19.7956683, -104.2327023],
      [19.7946891, -104.2300415],
      [19.7942954, -104.2297518],
      [19.7936089, -104.2300093],
      [19.7935079, -104.2312002],
      [19.7928114, -104.2312431],
      [19.7921956, -104.2314684],
      [19.7920644, -104.2316937],
      [19.7918019, -104.2317688],
      [19.7912568, -104.2310500],
      [19.7917716, -104.2296231],
      [19.7915697, -104.2292905],
      [19.7915192, -104.2289472],
      [19.7908429, -104.2280352],
      [19.7905804, -104.2271984],
      [19.7907015, -104.2266512],
      [19.7903760, -104.2259726],
      [19.7901084, -104.2256373]
    ]).addTo(this.map);

    var polygon9 = L.polygon([
      [19.7564046, -104.2898011],
      [19.7544255, -104.2664337],
      [19.7524868, -104.2581940],
      [19.7434391, -104.2561340],
      [19.7369762, -104.2472076],
      [19.7298667, -104.2403412],
      [19.7299475, -104.2225313],
      [19.7402481, -104.2163944],
      [19.7349969, -104.2027044],
      [19.7474682, -104.1931450],
      [19.7486699, -104.1929626],
      [19.7498816, -104.1931558],
      [19.7505077, -104.1948724],
      [19.7518608, -104.1965675],
      [19.7519415, -104.1980696],
      [19.7514165, -104.1992927],
      [19.7503663, -104.1998720],
      [19.7501038, -104.2008376],
      [19.7507702, -104.2012239],
      [19.7516992, -104.2014384],
      [19.7522445, -104.2018461],
      [19.7543044, -104.2066312],
      [19.7532138, -104.2099357],
      [19.7542034, -104.2108798],
      [19.7562027, -104.2105150],
      [19.7572932, -104.2122316],
      [19.7576971, -104.2139053],
      [19.7589491, -104.2156219],
      [19.7607666, -104.2160940],
      [19.7628264, -104.2159653],
      [19.7638361, -104.2152357],
      [19.7656131, -104.2156649],
      [19.7675921, -104.2158794],
      [19.7674709, -104.2166519],
      [19.7684806, -104.2172098],
      [19.7695710, -104.2173386],
      [19.7732864, -104.2172956],
      [19.7742153, -104.2189264],
      [19.7729230, -104.2190552],
      [19.7730441, -104.2201281],
      [19.7724787, -104.2206860],
      [19.7726403, -104.2220592],
      [19.7739326, -104.2217588],
      [19.7729230, -104.2226601],
      [19.7752249, -104.2274237],
      [19.7762345, -104.2259216],
      [19.7780518, -104.2257071],
      [19.7795864, -104.2263508],
      [19.7864514, -104.2251921],
      [19.7880263, -104.2258358],
      [19.7889550, -104.2253637],
      [19.7895002, -104.2253476],
      [19.7900050, -104.2255247],
      [19.7901059, -104.2256373],
      [19.7899646, -104.2417145],
      [19.7828574, -104.2417145],
      [19.7808786, -104.2457914],
      [19.7751038, -104.2431307],
      [19.7738518, -104.2477655],
      [19.7783749, -104.2541170],
      [19.7763557, -104.2602110],
      [19.7693085, -104.2636657],
      [19.7673497, -104.2712617],
      [19.7641592, -104.2753601],
      [19.7673295, -104.2815399],
      [19.7679959, -104.2890930],
      [19.7628567, -104.2918289],
      [19.7563945, -104.2898011],
    ]).addTo(this.map);

    var polygon10 = L.polygon([
      [19.7264986, -104.2132831],
      [19.7111218, -104.2046356],
      [19.7091624, -104.2039490],
      [19.7234234, -104.1977906],
      [19.7253422, -104.2101502],
      [19.7264936, -104.2132884],
    ]).addTo(this.map);

    var polygon11 = L.polygon([
      [19.7434290, -104.2561340],
      [19.7415003, -104.2650604],
      [19.7460242, -104.2719269],
      [19.7460242, -104.2842865],
      [19.7214641, -104.2801666],
      [19.7272813, -104.2698669],
      [19.7208177, -104.2671204],
      [19.7072433, -104.2650604],
      [19.7104754, -104.2485809],
      [19.7060180, -104.2389947],
      [19.7144347, -104.2375517],
      [19.7156466, -104.2300844],
      [19.7151214, -104.2197418],
      [19.7137479, -104.2136908],
      [19.7092634, -104.2041206],
      [19.7112430, -104.2046785],
      [19.7265138, -104.2133904],
      [19.7299071, -104.2225313],
      [19.7299071, -104.2403412],
      [19.7369358, -104.2472506],
      [19.7402885, -104.2516279],
      [19.7433987, -104.2561340],
    ]).addTo(this.map);

    var polygon12 = L.polygon([
      [19.7097078, -104.3042529],
      [19.7117682, -104.2959595],
      [19.7195250, -104.2987061],
      [19.7279276, -104.2911530],
      [19.7296445, -104.2816257],
      [19.7214843, -104.2801881],
      [19.7272813, -104.2699313],
      [19.7209389, -104.2671633],
      [19.7072837, -104.2650604],
      [19.7104350, -104.2486668],
      [19.7060313, -104.2390108],
      [19.7021122, -104.2396545],
      [19.6985971, -104.2436028],
      [19.6950819, -104.2478085],
      [19.6801719, -104.2601681],
      [19.6749995, -104.2725706],
      [19.6833237, -104.2884922],
      [19.7027183, -104.2904234],
      [19.7072837, -104.3028688],
      [19.7097078, -104.3042421],
    ]).addTo(this.map);

    var polygon13 = L.polygon([
      [19.7681777, -104.3486810],
      [19.7653405, -104.3448615],
      [19.7608878, -104.3474579],
      [19.7583029, -104.3515778],
      [19.7503119, -104.3551505],
      [19.7480438, -104.3529081],
      [19.7441258, -104.3523073],
      [19.7403289, -104.3516636],
      [19.7373801, -104.3535089],
      [19.7345525, -104.3557405],
      [19.7343102, -104.3462563],
      [19.7382688, -104.3420506],
      [19.7408944, -104.3364716],
      [19.7324116, -104.3350983],
      [19.7261098, -104.3357420],
      [19.7273621, -104.3296051],
      [19.7175050, -104.3282747],
      [19.7195250, -104.3201208],
      [19.7176262, -104.3138123],
      [19.7124954, -104.3110228],
      [19.7156870, -104.3076324],
      [19.7097482, -104.3042421],
      [19.7117682, -104.2960453],
      [19.7196058, -104.2986631],
      [19.7279680, -104.2911100],
      [19.7296243, -104.2816687],
      [19.7460242, -104.2842865],
      [19.7460141, -104.2719162],
      [19.7415053, -104.2650765],
      [19.7434290, -104.2561769],
      [19.7524868, -104.2582369],
      [19.7544053, -104.2663050],
      [19.7564046, -104.2897797],
      [19.7602466, -104.3000901],
      [19.7550718, -104.3028259],
      [19.7647751, -104.3172616],
      [19.7647625, -104.3275505],
      [19.7667843, -104.3439871],
      [19.7704317, -104.3514115],
      [19.7721960, -104.3550096],
      [19.7707428, -104.3581016],
      [19.7705800, -104.3605048],
      [19.7678255, -104.3601595],
      [19.7681777, -104.3486803],
    ]).addTo(this.map);

    var polygon14 = L.polygon([
      [19.7962840, -104.3749130],
      [19.7805960, -104.3635941],
      [19.7749422, -104.3689156],
      [19.7776883, -104.3720055],
      [19.7779206, -104.3722951],
      [19.7783345, -104.3721771],
      [19.7797883, -104.3690872],
      [19.7815551, -104.3696558],
      [19.7912265, -104.3728799],
      [19.7912769, -104.3728852],
      [19.7951231, -104.3783677],
      [19.7963042, -104.3749344],
    ]).addTo(this.map);

    var polygon15 = L.polygon([
      [19.7973945, -104.3702888],
      [19.7951332, -104.3593025],
      [19.7912568, -104.3610191],
      [19.7873802, -104.3611908],
      [19.7791422, -104.3601608],
      [19.7791422, -104.3644524],
      [19.7792128, -104.3648708],
      [19.7805859, -104.3636155],
      [19.7963042, -104.3749237],
      [19.7977074, -104.3707931],
      [19.7973945, -104.3702942],
    ]).addTo(this.map);

    var polygon16 = L.polygon([
      [19.7791220, -104.3601716],
      [19.7752653, -104.3594742],
      [19.7742961, -104.3644524],
      [19.7791523, -104.3646240],
      [19.7791321, -104.3601823],
    ]).addTo(this.map);

    var polygon17 = L.polygon([
      [19.7749220, -104.3689263],
      [19.7710653, -104.3684006],
      [19.7715499, -104.3641090],
      [19.7739730, -104.3642807],
      [19.7742658, -104.3644309],
      [19.7791523, -104.3646348],
      [19.7792128, -104.3649030],
      [19.7749119, -104.3689370],
    ]).addTo(this.map);

    var polygon18 = L.polygon([
      [19.7693085, -104.3797410],
      [19.7694498, -104.3728638],
      [19.7730037, -104.3726921],
      [19.7749725, -104.3689263],
      [19.7779206, -104.3723059],
      [19.7773754, -104.3725097],
      [19.7767090, -104.3744087],
      [19.7775167, -104.3785286],
      [19.7756187, -104.3788612],
      [19.7738114, -104.3804115],
      [19.7693085, -104.3797195],
    ]).addTo(this.map);

    var polygon19 = L.polygon([
      [19.7677940, -104.3601608],
      [19.7587876, -104.3581009],
      [19.7600800, -104.3555260],
      [19.7546073, -104.3532407],
      [19.7583130, -104.3515670],
      [19.7608676, -104.3474579],
      [19.7653405, -104.3448293],
      [19.7681777, -104.3486810],
      [19.7678243, -104.3601716],
    ]).addTo(this.map);

    var polygon20 = L.polygon([
      [19.7694498, -104.3728609],
      [19.7694498, -104.3684006],
      [19.7710552, -104.3684006],
      [19.7749574, -104.3689370],
      [19.7729987, -104.3726867],
      [19.7694523, -104.3728611],
    ]).addTo(this.map);

    var polygon21 = L.polygon([
      [19.7694498, -104.3684113],
      [19.7671882, -104.3678856],
      [19.7678243, -104.3601608],
      [19.7705503, -104.3605042],
      [19.7715398, -104.3641305],
      [19.7710552, -104.3683791],
      [19.7694195, -104.3684220]
    ]).addTo(this.map);

    var polygon22 = L.polygon([
      [19.7715499, -104.3641305],
      [19.7705806, -104.3605471],
      [19.7707422, -104.3581116],
      [19.7752754, -104.3594635],
      [19.7742961, -104.3644631],
      [19.7739730, -104.3642807],
      [19.7715196, -104.3641090]
    ]).addTo(this.map);

    var polygon23 = L.polygon([
      [19.7693287, -104.3738508],
      [19.7638159, -104.3737006],
      [19.7621701, -104.3709487],
      [19.7694296, -104.3711472],
      [19.7693892, -104.3738723],
    ]).addTo(this.map);

    var polygon24 = L.polygon([
      [19.7621600, -104.3709004],
      [19.7610493, -104.3677139],
      [19.7671983, -104.3678963],
      [19.7694498, -104.3684113],
      [19.7694498, -104.3711150],
      [19.7621701, -104.3709111]
    ]).addTo(this.map);

    var polygon25 = L.polygon([
      [19.7663300, -104.3865001],
      [19.7665420, -104.3846333],
      [19.7673396, -104.3832707],
      [19.7692984, -104.3797088],
      [19.7694094, -104.3738830],
      [19.7637957, -104.3737221],
      [19.7621802, -104.3709755],
      [19.7600800, -104.3720055],
      [19.7597569, -104.3783569],
      [19.7584645, -104.3802452],
      [19.7597569, -104.3821335],
      [19.7589592, -104.3828094],
      [19.7604031, -104.3855453],
      [19.7638159, -104.3835068],
      [19.7663098, -104.3864894]
    ]).addTo(this.map);

    var polygon26 = L.polygon([
      [19.7616753, -104.3695378],
      [19.7570206, -104.3707716],
      [19.7541428, -104.3745911],
      [19.7537692, -104.3817902],
      [19.7542539, -104.3835175],
      [19.7589592, -104.3828201],
      [19.7597670, -104.3821228],
      [19.7584645, -104.3802452],
      [19.7597468, -104.3783677],
      [19.7600901, -104.3719947],
      [19.7621701, -104.3709648],
      [19.7616753, -104.3695271],
    ]).addTo(this.map);

    var polygon27 = L.polygon([
      [19.7610392, -104.3677247],
      [19.7568489, -104.3677139],
      [19.7540317, -104.3660510],
      [19.7518578, -104.3667054],
      [19.7570105, -104.3708038],
      [19.7616854, -104.3695378],
      [19.7610493, -104.3677139]
    ]).addTo(this.map);

    var polygon28 = L.polygon([
      [19.7675618, -104.3631649],
      [19.7625033, -104.3629503],
      [19.7589542, -104.3646401],
      [19.7540620, -104.3660510],
      [19.7568388, -104.3677139],
      [19.7620388, -104.3677354],
      [19.7672084, -104.3678856],
      [19.7675719, -104.3631649]
    ]).addTo(this.map);

    var polygon29 = L.polygon([
      [19.7675794, -104.3631649],
      [19.7678243, -104.3601930],
      [19.7587825, -104.3580955],
      [19.7600800, -104.3555367],
      [19.7546059, -104.3532407],
      [19.7535319, -104.3537101],
      [19.7524540, -104.3541929],
      [19.7503007, -104.3551612],
      [19.7538399, -104.3585086],
      [19.7589592, -104.3646240],
      [19.7625008, -104.3629503],
      [19.7675769, -104.3631622]
    ]).addTo(this.map);

    var polygon30 = L.polygon([
      [19.7751050, -104.3103804],
      [19.7860678, -104.3138015],
      [19.7938613, -104.3055832],
      [19.7970512, -104.3001437],
      [19.8064353, -104.2971276],
      [19.8067419, -104.2993712],
      [19.8119403, -104.3069458],
      [19.8171082, -104.3186402],
      [19.8190462, -104.3316650],
      [19.8173908, -104.3351090],
      [19.8157759, -104.3385744],
      [19.8132020, -104.3495178],
      [19.8204088, -104.3543673],
      [19.8300173, -104.3632454],
      [19.8267876, -104.3687493],
      [19.8261416, -104.3756104],
      [19.8270298, -104.3771855],
      [19.8119302, -104.3742371],
      [19.8138682, -104.3797302],
      [19.8053085, -104.3817902],
      [19.7973894, -104.3702942],
      [19.7951383, -104.3593186],
      [19.7912668, -104.3610191],
      [19.7874206, -104.3611479],
      [19.7769211, -104.3597746],
      [19.7737307, -104.3590879],
      [19.7707624, -104.3580794],
      [19.7722162, -104.3550539],
      [19.7668247, -104.3440032],
      [19.7648054, -104.3276310],
      [19.7683594, -104.3271589],
      [19.7719941, -104.3268585],
      [19.7763557, -104.3208075],
      [19.7750634, -104.3103790],
    ]).addTo(this.map);

    polygon1.setStyle({ fillColor: '#000000', color: '#000000' });
    polygon2.setStyle({ fillColor: '#FF0000', color: '#FF0000' });
    polygon3.setStyle({ fillColor: '#FF006E', color: '#FF006E' });
    polygon4.setStyle({ fillColor: '#B200FF', color: '#B200FF' });
    polygon5.setStyle({ fillColor: '#7F006E', color: '#7F006E' });
    polygon6.setStyle({ fillColor: '#228B22', color: '#228B22' });
    polygon7.setStyle({ fillColor: '#808000', color: '#808000' });
    polygon8.setStyle({ fillColor: '#006400', color: '#006400' });
    polygon9.setStyle({ fillColor: '#ADFF2F', color: '#ADFF2F' });
    polygon10.setStyle({ fillColor: '#4682B4', color: '#4682B4' });
    polygon11.setStyle({ fillColor: '#4169E1', color: '#4169E1' });
    polygon12.setStyle({ fillColor: '#000080', color: '#000080' });
    polygon13.setStyle({ fillColor: '#8A2BE2', color: '#8A2BE2' });
    polygon14.setStyle({ fillColor: '#DA70D6', color: '#DA70D6' });
    polygon15.setStyle({ fillColor: '#FFC0CB', color: '#FFC0CB' });
    polygon16.setStyle({ fillColor: '#A52A2A', color: '#A52A2A' });
    polygon17.setStyle({ fillColor: '#808080', color: '#808080' });
    polygon18.setStyle({ fillColor: '#7D1935', color: '#7D1935' });
    polygon19.setStyle({ fillColor: '#000000', color: '#000000' });
    polygon20.setStyle({ fillColor: '#FFFF00', color: '#FFFF00' });
    polygon21.setStyle({ fillColor: '#FFA500', color: '#FFA500' });
    polygon22.setStyle({ fillColor: '#008000', color: '#008000' });
    polygon23.setStyle({ fillColor: '#0000FF', color: '#0000FF' });
    polygon24.setStyle({ fillColor: '#800080', color: '#800080' });
    polygon25.setStyle({ fillColor: '#FF69B4', color: '#FF69B4' });
    polygon26.setStyle({ fillColor: '#40E0D0', color: '#40E0D0' });
    polygon27.setStyle({ fillColor: '#00FF00', color: '#00FF00' });
    polygon28.setStyle({ fillColor: '#FFD700', color: '#FFD700' });
    polygon29.setStyle({ fillColor: '#C0C0C0', color: '#C0C0C0' });
    polygon30.setStyle({ fillColor: '#950101', color: '#950101' });

    polygon1.bindPopup('<p style="text-align: center">Sección 0216<p/>');
    polygon2.bindPopup('<p style="text-align: center">Sección 0215<p/>');
    polygon3.bindPopup('<p style="text-align: center">Sección 0217<p/>');
    polygon4.bindPopup('<p style="text-align: center">Sección 0220<p/>');
    polygon5.bindPopup('<p style="text-align: center">Sección 0214<p/>');
    polygon6.bindPopup('<p style="text-align: center">Sección 0223<p/>');
    polygon7.bindPopup('<p style="text-align: center">Sección 0212<p/>');
    polygon8.bindPopup('<p style="text-align: center">Sección 0218<p/>');
    polygon9.bindPopup('<p style="text-align: center">Sección 0219<p/>');
    polygon10.bindPopup('<p style="text-align: center">Sección 0213 <p/>');
    polygon11.bindPopup('<p style="text-align: center">Sección 0221 <p/>');
    polygon12.bindPopup('<p style="text-align: center">Sección 0222 <p/>');
    polygon13.bindPopup('<p style="text-align: center">Sección 0206 <p/>');
    polygon14.bindPopup('<p style="text-align: center">Sección 0194 <p/>');
    polygon15.bindPopup('<p style="text-align: center">Sección 0195 <p/>');
    polygon16.bindPopup('<p style="text-align: center">Sección 0196 <p/>');
    polygon17.bindPopup('<p style="text-align: center">Sección 0199 <p/>');
    polygon18.bindPopup('<p style="text-align: center">Sección 0201 <p/>');
    polygon19.bindPopup('<p style="text-align: center">Sección 0207 <p/>');
    polygon20.bindPopup('<p style="text-align: center">Sección 0200 <p/>');
    polygon21.bindPopup('<p style="text-align: center">Sección 0205 <p/>');
    polygon22.bindPopup('<p style="text-align: center">Sección 0198 <p/>');
    polygon23.bindPopup('<p style="text-align: center">Sección 0203 <p/>');
    polygon24.bindPopup('<p style="text-align: center">Sección 0204 <p/>');
    polygon25.bindPopup('<p style="text-align: center">Sección 0202 <p/>');
    polygon26.bindPopup('<p style="text-align: center">Sección 0210 <p/>');
    polygon27.bindPopup('<p style="text-align: center">Sección 0211 <p/>');
    polygon28.bindPopup('<p style="text-align: center">Sección 0209 <p/>');
    polygon29.bindPopup('<p style="text-align: center">Sección 0208 <p/>');
    polygon30.bindPopup('<p style="text-align: center">Sección 0197 <p/>');
  }
}

