import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { CommonModule } from '@angular/common';
import { IndexComponent } from '../index/index.component';
import { AuthGuard } from 'src/app/core/guard/auth.guard';
import { UsuariosComponent } from '../usuarios/usuarios.component';
import { RegistrosComponent } from '../registros/registros.component';
import { UbicacionesComponent } from '../ubicaciones/ubicaciones.component';
import { LetrerosComponent } from '../registros/letreros/letreros.component';
import { CalcomoniasComponent } from '../registros/calcomonias/calcomonias.component';

const routes: Routes = [
  /*{
    path: '',
    component: DashboardComponent,
  },*/
  {
    path: 'indice',
    component: IndexComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'usuarios',
    component: UsuariosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registros',
    component: RegistrosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registros/espacios',
    component: LetrerosComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registros/calcomonias',
    component: CalcomoniasComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'ubicaciones',
    component: UbicacionesComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
