import { Component, HostListener } from '@angular/core';
import { environment } from "../environments/environment";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  message: any = null;

  constructor() {

  }

  /*@HostListener('window:beforeunload', ['$event'])
beforeunloadHandler(event:any) {
    localStorage.clear();
    this.api.logueado = false;
}*/
  ngOnInit(): void {
    this.listen();
  }

  requestPermission() {
    const firebaseApp = initializeApp(environment.firebase);
    const messaging = getMessaging(firebaseApp);
    getToken(messaging,
      { vapidKey: "BJ-T9gj7RldK_NLQF_PLvJRxMnjoHKpGkOFihC4QDUCCKKVilPe6U_pvDpW5_JRxcnGY4AsqWOla5wPd53leoK8" }).then(
        (currentToken) => {
          if (currentToken) {
            console.log("Hurraaa!!! we got the token.....");
            console.log(currentToken);

            localStorage.setItem('tokenNoti', currentToken);

          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
  }
  listen() {
    const firebaseApp = initializeApp(environment.firebase);
    const messaging = getMessaging(firebaseApp);

    onMessage(messaging, (payload) => {
      console.log('Message received. ', payload);
      this.message = payload;
    });
  }



}

